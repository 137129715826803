import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import {
  FaFacebook,
  FaInstagram,
  // FaTwitter, FaLinkedin, FaDribbble, FaGithub 
} from '@meronex/icons/fa';
import map from 'ramda/src/map'
import addIndex from 'ramda/src/addIndex'
import { media } from '../../../constants/responsive';
import './index.css'
import Checkbox from '../../../../static/svg/icons/checkbox';
import { useIntl } from "react-intl";

const Welcome = ({ homepage }) => {
  const mapIndexed = addIndex(map)
  const intl = useIntl();

  return (
    <>
      {homepage.headerDescription &&
        <MainWrapper>
          <Wrapper>
            <LeftGapCell />
            <ContentWrapper middle>
              <Name>
                <HeaderNameContainer className="text-focus-in">
                  {homepage.headerTitle}
                </HeaderNameContainer>
              </Name>

              <ContentTextWrapper>
                <ul>
                  <StyledLi><Checkbox width="25" height="100%" backgroundfillopacity="0" mainColor="#FFFFFF" />&nbsp;{intl.formatMessage({ id: "header_info.option_1" })}</StyledLi>
                  <StyledLi><Checkbox width="25" height="100%" backgroundfillopacity="0" mainColor="#FFFFFF" />&nbsp;{intl.formatMessage({ id: "header_info.option_2" })}</StyledLi>
                  <StyledLi><Checkbox width="25" height="100%" backgroundfillopacity="0" mainColor="#FFFFFF" />&nbsp;{intl.formatMessage({ id: "header_info.option_3" })}</StyledLi>
                  <StyledLi><Checkbox width="25" height="100%" backgroundfillopacity="0" mainColor="#FFFFFF" />&nbsp;{intl.formatMessage({ id: "header_info.option_4" })}</StyledLi>
                  <StyledLi><Checkbox width="25" height="100%" backgroundfillopacity="0" mainColor="#FFFFFF" />&nbsp;{intl.formatMessage({ id: "header_info.option_5" })}</StyledLi>
                </ul>
              </ContentTextWrapper>

              {
                /*
              <StyledH3>{intl.formatMessage({ id: "header_info.footer_title" })}</StyledH3>


             
                              <Content >
                                <HeaderContentContainer className="text-focus-in" dangerouslySetInnerHTML={{ __html: homepage.headerDescription }} />
                              </Content>
                    */
              }

              <SocialLinks>
                {mapIndexed((iconItem, index) => {
                  if (iconItem.enabled) {
                    return <li key={index} className="list-inline-item">
                      <a title={iconItem.linkTitle} target="_blank" rel="noreferrer noopener" href={iconItem.linkUrl}>
                        <i className={iconItem.iconClass} />
                      </a>
                    </li>
                  }
                },
                  homepage.headerIcons
                )}
                <li>
                  <A href="https://www.facebook.com/Unlock2Ride-100518629239097" target="_blank" rel="noreferrer noopener">
                    <FaFacebook />
                  </A>
                </li>
                <li>
                    <A href="https://www.instagram.com/unlock2ride" target="_blank" rel="noreferrer noopener">
                      <FaInstagram />
                    </A>
                  </li>
                {
                  /*
                  <li>
                    <A href="http://twitter.hugo.im" target="_blank" rel="noreferrer noopener">
                      <FaTwitter />
                    </A>
                  </li>
                  <li>
                    <A href="http://linkedin.hugo.im" target="_blank" rel="noreferrer noopener">
                      <FaLinkedin />
                    </A>
                  </li>
                  
                  
                  <li>
                    <A href="http://dribbble.hugo.im" target="_blank" rel="noreferrer noopener">
                      <FaDribbble />
                    </A>
                  </li>
                  <li>
                    <A href="http://github.hugo.im" target="_blank" rel="noreferrer noopener">
                      <FaGithub />
                    </A>
                  </li>
                  <li>
                    <A href="http://github.hugo.im" target="_blank" rel="noreferrer noopener">
                    <i class="fab fa-instagram"></i>
                    </A>
                  </li>
                  */
                }
              </SocialLinks>
            </ContentWrapper>
            {
              /*
                <PictureContainer center>
                  <FormattedMessage id="image_alt_homepage">{(txt) =>
                    <ProfilePicture src={Avatar} alt={txt} />
                  }</FormattedMessage>
                </PictureContainer>
              */
            }
            <RightGapCell />
          </Wrapper>
        </MainWrapper>
      }
    </>
  );
};

const HeaderNameContainer = styled.h2`
  word-wrap: break-word;
  color: #FFFFFF;
  font-size: 3rem;
  text-align: left;
  @media (max-width: 768px) {
    font-size: 1.5rem;
    padding-left: 10px;
    padding-right: 10px;
    text-align: center;
  }
`;

const MainWrapper = styled.div`
  padding-top: 2rem;
  background: url('/img/bicycleWithLock.webp') no-repeat center center fixed;
  /*background-position: right -250px bottom 0px;*/
  -webkit-background-size: 100% auto;
  -o-background-size: 100% auto;
  background-size: 100% auto;
  height: 639px; 
  width: 100%;
  @media (max-width: 768px) {
    background: url('/img/bicycleWithLockForMobile.webp') no-repeat left fixed;
    height: 450px; 
  }
`;

const Wrapper = styled.div`
  margin: ${(props) => props.theme.welcome.margin};
  font-family: ${(props) => props.theme.blog.author.fontFamily};
  font-size: ${(props) => props.theme.blog.author.fontSize};
  line-height: ${(props) => props.theme.blog.author.lineHeight};
  height: auto;
  grid-auto-flow: row dense;
  grid-auto-rows: minmax(20px, auto);
  grid-template-columns: auto 4.2rem 4.2rem auto;
  grid-gap: 0px;
`;

const ContentWrapper = styled.div`
  grid-column: 1 / span 4;
  grid-row: 2 / span 1;
  text-align: center;
  padding-left: 17%;
  @media (max-width: 768px) {
    padding-left: 0px;
  }
`;

const LeftGapCell = styled.div`
  grid-column: 1 / span 1;
  grid-row: 1 / span 1;
`;

const RightGapCell = styled.div`
  grid-column: 4 / span 1;
  grid-row: 1 / span 1;
`;

const Name = styled.div`
  font-size: 1.6rem;
  font-family: ${(props) => props.theme.fonts.MainFont};
  font-weight: 500;
  ${media.md`
    margin-top: 0;
    font-size: 2.625rem;
  `}
  .name {
    color: ${({ theme }) => theme.colors.brand};
  }
`;

const SocialLinks = styled.ul`
  margin-top: 26px;
  margin-bottom: 30px;
  display: none;
  list-style: none;
  li {
    display: inline-block;
    margin-right: 5px;
  }
  ${media.md`
    margin-bottom: 0px;
  `}
  @media (max-width: 768px) {
    display: none;
  }
`;

const A = styled.a`
  text-decoration: none;
  font-size: 1.5rem;
  display: inline-block;
  padding: ${(props) => props.theme.socialLinks.a.padding};
  transition: 0.3s;
  opacity: 0.8;
  &:hover {
    opacity: 1;
    color: ${(props) => props.theme.socialLinks.a.hover.color};
    transition: 0.3s;
  }
`;


const StyledLi = styled.li`
  list-style: none;
  font-size: 1.5rem;
  text-align: left;
  color: #FFFFFF;
  @media (max-width: 768px) {
    font-size: 1rem;
  }
`;


const ContentTextWrapper = styled.div`
`;

Welcome.propTypes = {
  homepage: PropTypes.object.isRequired,
  className: PropTypes.string
};

export default Welcome;
