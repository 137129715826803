import _taggedTemplateLiteralLoose from "@babel/runtime/helpers/esm/taggedTemplateLiteralLoose";
var _templateObject, _templateObject2, _templateObject3, _templateObject4, _templateObject5, _templateObject6, _templateObject7, _templateObject8;
import styled from 'styled-components';
import { breakpointsUp } from '../../../../../themes/breakpoints';
import { MDBCardImage } from 'mdbreact'; // Breakpoint breakpointsUp.tablet
// has 2 productCombinations in row thats why width is much lower than at mobileL
export var MDBCardImageFixedDimensions = styled(MDBCardImage)(_templateObject || (_templateObject = _taggedTemplateLiteralLoose(["\n  margin-left: auto;\n  margin-right: auto;\n  background-color: #FFFFFF;\n  @media ", " { \n    max-width: 290px;\n    max-height: 290px;\n  }\n  @media ", " { \n    max-width: 344px;\n    max-height: 344px;\n  }\n  @media ", " { \n    max-width: 344px;\n    max-height: 344px;\n  }\n  @media ", " { \n    max-width: 290px;\n    max-height: 290px;\n  }\n  @media ", " { \n    max-width: 500px;\n    max-height: 500px;\n  }\n"])), breakpointsUp.mobileS, breakpointsUp.mobileM, breakpointsUp.mobileL, breakpointsUp.tablet, breakpointsUp.laptop);
export var Price = styled.div(_templateObject2 || (_templateObject2 = _taggedTemplateLiteralLoose(["\n    font-size: 1.2rem;\n    color: #055705;\n    padding-top: 0.2rem;\n"])));
export var ProductTitle = styled.strong(_templateObject3 || (_templateObject3 = _taggedTemplateLiteralLoose(["\n  color: #055705;\n  font-size: 1.3rem;\n  text-transform: uppercase;\n"]))); // !!!! Important: If we remove this wrapper then locally it will work, but after we publish watermarks will be positioned incorrectly.
export var WatermarkWrapper = styled.div(_templateObject4 || (_templateObject4 = _taggedTemplateLiteralLoose(["\n  position: relative;\n"])));
export var WatermarkInfo = styled.div(_templateObject5 || (_templateObject5 = _taggedTemplateLiteralLoose(["\n  position: absolute;\n  top: 10px;\n  right: 10px;\n  cursor: pointer;\n"])));
export var WatermarkSubscriptionIncluded = styled.div(_templateObject6 || (_templateObject6 = _taggedTemplateLiteralLoose(["\n  position: absolute;\n  top: 10px;\n  left: 10px;\n  cursor: pointer;\n"])));
export var WatermarkPriceFrom = styled.div(_templateObject7 || (_templateObject7 = _taggedTemplateLiteralLoose(["\n  position: absolute;\n  bottom: 10px;\n  right: 10px;\n  background: gold;\n  width: 100px; \n  height: 100px;\n  border-radius: 50%;\n  display: flex;\n  align-items: center; \n  justify-content: center;\n  text-align: center;\n  background-image: radial-gradient(white, white, #055705);\n  color: #055705;\n  font-size: 0.8rem;\n  font-weight: bold;\n"])));
export var WatermarkA = styled.div(_templateObject8 || (_templateObject8 = _taggedTemplateLiteralLoose(["\n  cursor: pointer;\n  background: transparent;\n  border-radius:50%;\n  color: #00C851;\n  transition: 0.5s all ease-out;\n  &:hover {\n    background-color: #00C851;\n    color: white;\n  }\n"])));
